import {Inject, Injectable} from '@angular/core';
import {version} from '../../../package.json';
import {WORKS_CORE_CONFIG} from '../core/injection-tokens';
import {CoreConfig} from '../core/core-config.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ConfigService {

    private static readonly VERSION: any = version;
    private readonly backEndUrlSource = new BehaviorSubject<string>(this.config.backendUrl);
    public readonly backEndUrl$ = this.backEndUrlSource.asObservable();

    public constructor(@Inject(WORKS_CORE_CONFIG) private config: CoreConfig) {}

    public get(key: string): any {
        return this.config[key];
    }

    public getBackendUrl(endpoint?: string): string {
        if (!endpoint) return this.get('backendUrl');

        return this.get('backendUrl') + endpoint;
    }

    public getCoreAppUrl(appKey: string, path?: string): string | undefined {
        const url = this.get('coreAppUrls')[appKey];
        if (!url) return undefined;
        if (!path) return url;

        return url + path;
    }

    public getContentOption(appKey: string) {
        if (this.get('content')[appKey]) {
            return this.get('content')[appKey];
        }
        return null;
    }

    public getVersion() {
        return ConfigService.VERSION;
    }
}

import {AuthGuardedResource} from '@app/core/resource/auth-guarded.resource';
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {AuthService} from '@app/core/services/auth';
import {ConfigService} from '@app/config';
import {Injectable} from '@angular/core';
import {MessageDto} from '@app/core/resource-dto/messages';

@Injectable({providedIn: 'root'})
export class MessagesResource extends AuthGuardedResource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);

    this.$setUrl(config.getBackendUrl('/messages'));
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get
  })
  list: IResourceMethod<any, MessageDto.MessagePageResponse>;

  @ResourceAction({
    method: ResourceRequestMethod.Post
  })
  add: IResourceMethod<MessageDto.MessageRequest, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!messageId}'
  })
  update: IResourceMethodStrict<MessageDto.MessageRequest, any, { messageId: number }, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{!userId}'
  })
  listMessageUser: IResourceMethodStrict<{ userId: number }, any, any, MessageDto.MessageUserPageResponse>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!messageId}/message-user/status'
  })
  updateStatus: IResourceMethodStrict<MessageDto.MessageUserStatus, any, { messageId: number }, void>;

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!messageId}/message-user'
  })
  deleteMessageUser: IResourceMethodStrict<{ messageId: number }, any, any, void>;
}

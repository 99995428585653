import {AuthGuardedResource} from "@app/core/resource/auth-guarded.resource";
import {Injectable} from "@angular/core";
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {ConfigService} from "@app/config";
import {InstructionDto} from "@app/core/resource-dto/instruction";
import {AuthService} from "@app/core/services/auth";

@Injectable({providedIn: 'root'})
export class InstructionResource extends AuthGuardedResource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);

    this.$setUrl(config.getBackendUrl('/instructions'));
  }

  @ResourceAction({
    expectJsonArray: false,
    method: ResourceRequestMethod.Get
  })
  getAll: IResourceMethod<any, InstructionDto.Instruction[]>

  @ResourceAction({
    method: ResourceRequestMethod.Put
  })
  update: IResourceMethodStrict<InstructionDto.InstructionInput, any, any, void>
}

import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceParams, ResourceRequestMethod, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
@ResourceParams({
    withCredentials: true
})
export class PropertyregistrySyncResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/propertyregistry-sync'));
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    propertyregistrySync: IResourceMethodStrict<{}, {}, void, {}>;
}

import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import {AuthService} from '@app/core/services/auth';

@Component({
    selector: 'app-session-timeout-modal',
    templateUrl: './session-timeout-modal.component.html'
})
export class SessionTimeoutModalComponent implements OnInit, OnChanges {

    private timerInterval: number;

    @Input()
    public sessionExpireTime: Date;

    public close: EventEmitter<void> = new EventEmitter<void>();

    public timeRemaining: number | null = null;

    constructor(
        private modalRef: BsModalRef,
        private authService: AuthService
    ) {
    }

    public get isDisabled(): boolean {
        return this.timeRemaining == null || this.timeRemaining < 0;
    }

    public ngOnInit(): void {
        if (!this.sessionExpireTime) {
            return;
        }

        this.countdown();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.sessionExpireTime && this.sessionExpireTime) {
            return;
        }

        this.countdown();
    }

    public hide(): void {
        this.modalRef.hide();
        this.close.next();
    }

    public logout(): void {
        this.authService.redirectToCasLogout();
        this.hide();
    }

    public extendSession(): void {
        if (this.timeRemaining >= 0) {
            this.authService.extendSession();
            this.hide();
        } else {
            window.location.reload();
        }
    }

    private countdown(): void {
        if (this.timerInterval) {
            window.clearInterval(this.timerInterval);
        }
        this.timeRemaining = undefined;
        if (!this.sessionExpireTime) {
            return;
        }

        this.updateTimeRemaining();
        this.timerInterval = window.setInterval(() => {
            this.updateTimeRemaining();
            if (this.timerInterval > 0) {
                return;
            }

            window.clearInterval(this.timerInterval);
        }, 1000);
    }

    private updateTimeRemaining(): void {
        this.timeRemaining = moment(this.sessionExpireTime).diff(new Date(), 's');
    }
}

import {Observable} from 'rxjs';
import {GpsCoordinatesModel} from '@app/core/models/gps-coordinates.model';
import {LocationModel} from '@app/core/resource-dto/location.model';

export class GeolocationUtil {

    private static readonly NULLISH = [null, undefined];
    private static readonly COORDINATE_TO_RADIAN = Math.PI / 180;
    public static readonly EARTH_RADIUS_METERS = 6371e3;

    public static getCoordinates(): Observable<GpsCoordinatesModel> {
        return new Observable<GpsCoordinatesModel>(observer => {
            if (!navigator.geolocation) {
                observer.error(new Error('Geolocation not supported!'));
                observer.complete();
            } else {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        observer.next(GpsCoordinatesModel.from(position.coords));
                        observer.complete();
                    },
                    err => {
                        observer.error(err);
                        observer.complete();
                    });
            }
        });
    }

    public static getOptionalCoordinates(): Observable<GpsCoordinatesModel> {
        return new Observable<GpsCoordinatesModel>(observer => {
            GeolocationUtil.getCoordinates().subscribe({
                next: value => observer.next(value),
                error: err => {
                    console.error(err);
                    observer.next(null);
                },
                complete: () => observer.complete()
            });
        });
    }

    public static getErrorMessage(error: any): string {
        if (!!error?.code) {
            switch (error.code) {
                // PERMISSION_DENIED
                case 1:
                    return 'Asukoha pärimine veebilehitseja või operatsioonisüsteemi poolt keelatud!';
                // POSITION_UNAVAILABLE
                case 2:
                    return 'Asukoht kättesaamatu!';
                // TIMEOUT
                case 3:
                    return 'Asukoha pärimine aegus!';
            }
        }
        if (!!error?.message) {
            return error.message;
        }

        return 'Teadmata viga!';
    }

    public static calculateDistanceInMeters(a: LocationModel, b: LocationModel): number | undefined {
        if (!GeolocationUtil.hasCoordinates(a) || !GeolocationUtil.hasCoordinates(b)) return undefined;

        const aLatitudeRadians = a.latitude * GeolocationUtil.COORDINATE_TO_RADIAN;
        const bLatitudeRadians = b.latitude * GeolocationUtil.COORDINATE_TO_RADIAN;
        const latitudeDelta = ((b.latitude - a.latitude) * GeolocationUtil.COORDINATE_TO_RADIAN) / 2;
        const longitudeDelta = ((b.longitude - a.longitude) * GeolocationUtil.COORDINATE_TO_RADIAN) / 2;

        const x = Math.pow(Math.sin(latitudeDelta), 2) +
            Math.cos(aLatitudeRadians) * Math.cos(bLatitudeRadians) *
            Math.pow(Math.sin(longitudeDelta), 2);
        const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

        return y * GeolocationUtil.EARTH_RADIUS_METERS;
    }

    public static hasCoordinates(location: LocationModel): boolean {
        if (!location) return false;

        return !GeolocationUtil.NULLISH.includes(location.latitude) &&
               !GeolocationUtil.NULLISH.includes(location.longitude);
    }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplianceTypeResource} from '@app/core/resource/appliance-type.resource';
import {ApplianceType} from '@app/core/resource-dto/appliance-type';
import {AuthService} from '@app/core/services/auth';

@Injectable({providedIn: 'root'})
export class ApplianceTypeService {

    private readonly applianceTypesSource = new BehaviorSubject<ApplianceType[]>([]);
    private readonly applianceTypeCodeToNameMapSource = new BehaviorSubject<Map<string, string>>(new Map<string, string>());
    public readonly applianceTypes$ = this.applianceTypesSource.asObservable();
    public readonly applianceTypeCodeToNameMap$ = this.applianceTypeCodeToNameMapSource.asObservable();

    public constructor(
        private applianceTypeResource: ApplianceTypeResource,
        private authService: AuthService
    ) {
    }

    public init(): void {
        this.authService.user$.subscribe(value => {
            if (!value?.id) return;

            this.loadApplianceTypes();
        });
    }

    public getApplianceName(code: string): string | undefined {
        return this.applianceTypeCodeToNameMapSource.value.get(code);
    }

    public getApplianceNameObservable(code: string): Observable<string | undefined> {
        return this.applianceTypeCodeToNameMap$.pipe(map(x => x?.get(code)));
    }

    private loadApplianceTypes(): void {
        this.applianceTypeResource.getAll().then(output => {
            this.updateApplianceTypeCodeToNameMap(output);
            this.applianceTypesSource.next(!!output?.length ? output : []);
        });
    }

    private updateApplianceTypeCodeToNameMap(applianceTypes: ApplianceType[]): void {
        const list = !!applianceTypes?.length ? applianceTypes : [];
        const map = new Map(list.map(obj => [obj.code, obj.name]));
        this.applianceTypeCodeToNameMapSource.next(map);
    }
}

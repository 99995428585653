import {Injectable} from '@angular/core';
import {IResourceMethod, IResourceMethodStrict, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth';
import {ContractDto} from '../resource-dto/contract';
import {ContractPersonDto} from '../resource-dto/contractor-person';
import {AuthGuardedResource} from './auth-guarded.resource';
import ContractPersonsInputDto = ContractPersonDto.ContractPersonsInputDto;
import ContractPersonsBulkInputDto = ContractPersonDto.ContractPersonsBulkInputDto;

@Injectable({providedIn: 'root'})
export class ContractorPersonResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/contractor-persons'));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<any, ContractDto.QueryOutput>;

    @ResourceAction({
        method: ResourceRequestMethod.Get
    })
    get: IResourceMethod<{contractId: number}, ContractDto.Contract>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    post: IResourceMethodStrict<ContractPersonsBulkInputDto, {}, void, {}>;

    @ResourceAction({
        method: ResourceRequestMethod.Put
    })
    put: IResourceMethodStrict<ContractPersonsInputDto, {}, void, {}>;
}

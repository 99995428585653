import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from "@app/core/services/auth";

@Injectable({providedIn: 'root'})
export class OrderFileRes extends AuthGuardedResource {

    constructor(
        protected httpClient: HttpClient,
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/orders'));
    }


    saveOrder(content: any) {
        const req = new HttpRequest<FormData>('POST', this.config.getBackendUrl('/orders/public'), content, {
            reportProgress: true,
            withCredentials: true
        });

        return this.httpClient.request(req);
    }
}

import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from "@app/core/services/auth";
import {UserNotificationPermissionsDto} from "@app/core/resource-dto/user-notification-permissions";

@Injectable({providedIn: 'root'})
export class UserNotificationPermissionsResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/users'));
    }

    @ResourceAction({
        path: '/{!userId}/notification-permissions',
        expectJsonArray: false,
        method: ResourceRequestMethod.Get
    })
    get: IResourceMethod<{userId: any}, UserNotificationPermissionsDto.UserNotificationSettingsResponse>;

    @ResourceAction({
      path: '/{!userId}/notification-permissions',
      expectJsonArray: false,
      method: ResourceRequestMethod.Put
    })
    put: IResourceMethod<{roleId: number, permissionIds: number[]}[], void>;
}

import {PaginationHelpers} from '@app/core/components/pagination';
import {MobilePagerSelectionInterface} from './mobile-pager-selection.interface';
import {NumberUtil} from '@app/shared/utils';

export class MobilePageHelpers {

    public static isValidSelection(current: number, max: number): boolean {
        if (!NumberUtil.isNaturalNumber(current)) return false;
        if (!NumberUtil.isNaturalNumber(max)) return false;

        return current <= max;
    }

    public static createPageSelectionRange(current: number, max: number): MobilePagerSelectionInterface[] {
        if (!NumberUtil.isNaturalNumber(current)) return [];
        if (!NumberUtil.isNaturalNumber(max)) return [];

        const {from, to} = PaginationHelpers.getRangeLimits(current, max, 2);

        return PaginationHelpers.range(from, to).map(index => ({
            index,
            page: index + 1,
            isActive: index === current
        }));
    }
}

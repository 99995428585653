import {OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PageModel} from '@app/core/components/pagination/page.model';
import {RowAbstract} from '@app/core/components/pagination/row.abstract';
import {PaginatedList} from '@app/core/components/pagination/paginated-list.abstract';
import {MetadataDto} from '@app/core/resource-dto/metadata';
import PagedRequest = MetadataDto.PagedRequest;
import StatsResponse = MetadataDto.StatsResponse;

export abstract class PaginatedListWithStats<P extends PagedRequest, R extends RowAbstract, S extends StatsResponse>
  extends PaginatedList<P, R> implements OnDestroy {

  protected statsSource: Subject<S> = new Subject<S>();
  public stats$: Observable<S> = this.statsSource.asObservable().pipe(takeUntil(this.ngDestroy));

  protected abstract mapQuery([filter, page]: [any, PageModel]): P;
  protected abstract query(queryInput: any): void;
  protected abstract queryStats(): void;

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}

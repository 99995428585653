import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class BooleanObservableUtil {

    public static or(...observables: Observable<boolean>[]): Observable<boolean> {
        return combineLatest(observables).pipe(map(values => values.some(value => !!value)));
    }

    public static and(...observables: Observable<boolean>[]): Observable<boolean> {
        return combineLatest(observables).pipe(map(values => values.every(value => !!value)));
    }

    public static invert(observable: Observable<boolean>): Observable<boolean> {
        return observable.pipe(map(value => !value));
    }
}

export enum ViewType {
    APPLIANCE_SCHEDULE = 'appliance_schedule',
    APPLIANCE_PLAN = 'appliance_plan',
    APPLIANCE_ORDER = 'appliance_order',
    ORDERS_WARRANTY = 'orders_warranty',
    ORDERS = 'orders',
    INSPECTION_PROBLEM = 'inspection_problem',
    CONSTRUCTION_CONTRACTS = 'construction_contracts',
    REPAIRS = 'repairs'
}

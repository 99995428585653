import {UserLoginDto} from '@app/core/resource-dto/user-login';
import User = UserLoginDto.User;

export class AuthHelpers {

    public static mapPermissions(user: User): string[] {
        const permissions: string[] = [];
        if (!user) return permissions;

        permissions.push('user');
        permissions.push(...user.permissions);
        user.substituteUserPermissions?.forEach(permission => permissions.push('substitute-' + permission));

        return permissions;
    }
}

export class StorageUtil {

    private static readonly APPLICATION_PREFIX = 'works';

    public static getItem(storage: Storage, key: string): any {
        return storage.getItem(this.getKey(key));
    }

    public static setItem(storage: Storage, key: string, value?: any): any {
        return storage.setItem(this.getKey(key), value);
    }

    public static removeItem(storage: Storage, key: string): any {
        storage.removeItem(this.getKey(key));
    }

    private static getKey(key: string) {
        return [this.APPLICATION_PREFIX, key].filter(part => !!part).join('.');
    }
}

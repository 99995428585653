import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '@app/core/services/auth';
import {AuthInterceptorHelpers} from '@app/core/interceptor/auth-interceptor.helpers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public constructor(private authService: AuthService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.withCredentials) return next.handle(req);

        return next.handle(req).pipe(
            tap({
                next: event => this.handleEvent(event),
                error: error => this.handleError(error)
            })
        );
    }

    private handleEvent(event: HttpEvent<any>): void {
        if (!(event instanceof HttpResponse)) return;

        this.updateExpirationDate(event?.headers);
    }

    private updateExpirationDate(headers: HttpHeaders): void {
        const expiration = AuthInterceptorHelpers.getExpirationDate(headers);
        if (!expiration) return;

        this.authService.setExpirationDate(expiration);
    }

    private handleError(error: any): void {
        if (!(error instanceof HttpErrorResponse)) return;

        if (error.status === 401) {
            this.authService.setCurrentUser(null);
            this.authService.redirectToCasLogin();
        } else {
            this.updateExpirationDate(error.headers);
        }
    }
}

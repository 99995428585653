import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth';
import {ServiceDto} from '../resource-dto/service';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
export class ServiceRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/services'));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<ServiceDto.QueryInput, ServiceDto.QueryServicesOutput>;

    @ResourceAction({
        path: '/{!serviceId}'
    })
    get: IResourceMethod<{serviceId: any}, ServiceDto.ServiceItem>;

    @ResourceAction({
        expectJsonArray: false,
        path: '/{!serviceId}/slas'
    })
    querySlas: IResourceMethod<ServiceDto.QueryServiceSlasInput, ServiceDto.QueryServiceSlasOutput>;
}

import {ArrayFilter} from './array.filter';

export class ArrayUtil {

    public static toDistinct(input: any[] | undefined | null): any[] | undefined | null {
        if (!input?.length) return input;

        return input.filter(ArrayFilter.distinct);
    }

    public static merge(input: any[][]): any[] | undefined | null {
        if (!input?.length) return input;

        return input.filter(x => !!x?.length).reduce((prev, curr) => prev.push(...curr) && prev, []);
    }

    public static isArray(input: any): boolean {
        if (!input?.length) return false;
        if (Array.isArray(input)) return true;

        return !!ArrayBuffer.isView(input);
    }

    public static toOccurrenceMap = (input: any[]): Map<any, number> => {
        return input.reduce((prev: Map<any, number>, curr: any) => prev.set(curr, (prev.get(curr) || 0) + 1), new Map());
    }
}

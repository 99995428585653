import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {MobilePageHelpers as Helper} from './mobile-pager.helpers';

@Component({
    selector: 'app-mobile-pager',
    templateUrl: './mobile-pager.component.html'
})
export class MobilePagerComponent implements OnDestroy {

    @Input()
    public set page(page: number) {
        if ([null, undefined].includes(page)) {
            console.error('Page number is missing! Setting to 0!');
            this.pageSubject.next(0);

            return;
        }

        if (page < 0) {
            console.error(`Page must not be negative! Current value ${page}{}! Setting to 0!`);
            this.pageSubject.next(0);

            return;
        }

        if (!Number.isInteger(page)) {
            console.error(`Page must be an integer! Current value ${page}{}! Rounding to integer`);
            this.pageSubject.next(Math.round(page));

            return;
        }

        this.pageSubject.next(page);
    }

    @Input()
    public set total(total: number) {
        if ([null, undefined].includes(total)) {
            console.error('Total pages is missing! Setting to 0!');
            this.totalSubject.next(0);

            return;
        }

        if (total < 0) {
            console.error(`Total pages must not be negative! Current value ${total}{}! Setting to 0!`);
            this.totalSubject.next(0);

            return;
        }

        if (!Number.isInteger(total)) {
            console.error(`Total pages must be an integer! Current value ${total}{}! Rounding to integer`);
            this.totalSubject.next(Math.round(total));

            return;
        }

        this.totalSubject.next(total);
    }

    @Input()
    public set disabled(disabled: boolean) {
        this.disabledSubject.next(!!disabled);
    }

    @Output()
    public readonly changePage = new EventEmitter<number>();

    private readonly ngDestroy = new Subject<void>();

    private readonly pageSubject = new BehaviorSubject<number | undefined>(undefined);
    private readonly page$ = this.pageSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), filter(x => x !== undefined), distinctUntilChanged());

    private readonly totalSubject = new BehaviorSubject<number | undefined>(undefined);
    private readonly total$ = this.totalSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), filter(x => x !== undefined), distinctUntilChanged());

    private readonly disabledSubject = new BehaviorSubject<boolean>(false);
    public readonly disabled$ = this.disabledSubject.pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    public readonly isFirstPage$ = this.page$
        .pipe(takeUntil(this.ngDestroy), map(page => page === 0), distinctUntilChanged());
    public readonly isLastPage$ = combineLatest([this.page$, this.total$])
        .pipe(takeUntil(this.ngDestroy), map(([page, total]) => !((page || 0) < (total || 0))), distinctUntilChanged());

    public readonly pages$ = combineLatest([this.page$, this.total$]).pipe(
        takeUntil(this.ngDestroy),
        filter(([current, max]) => Helper.isValidSelection(current, max)),
        map(([current, max]) => Helper.createPageSelectionRange(current, max)),
        distinctUntilChanged()
    );

    private get current(): number {
        return this.pageSubject.getValue() || 0;
    }

    private get max(): number {
        return this.totalSubject.getValue() || 0;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public previousPage(): void {
        if (this.current < 1) return;

        this.changePage.emit(this.current - 1);
    }

    public nextPage(): void {
        if (this.current + 1 > this.max) return;

        this.changePage.emit(this.current + 1);
    }

    public setPage(page: number): void {
        if ([null, undefined].includes(page)) return;
        if (this.current === page) return;
        if (this.max < page) return;
        if (page < 0) return;
        if (!Number.isInteger(page)) return;

        this.changePage.emit(page);
    }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {Observable} from 'rxjs';
import {AuthService} from "@app/core/services/auth";
import {FileDto} from "@app/core/resource-dto/file";

@Injectable({providedIn: 'root'})
export class FileRes extends AuthGuardedResource {

  constructor(
    protected httpClient: HttpClient,
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);

    this.$setUrl(config.getBackendUrl('/files'));
  }

  upload(file: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const req = new HttpRequest<FormData>('POST', this.config.getBackendUrl('/files'), formData, {
      reportProgress: true,
      withCredentials: true
    });

    return this.httpClient.request(req);
  }

  @ResourceAction({
    expectJsonArray: false,
    method: ResourceRequestMethod.Get,
    path: '/{id}'
  })
  getOne: IResourceMethod<{ id: number }, FileDto.File>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/{id}/download'
  })
  getFile: IResourceMethodStrict<{ id: number }, any, any, File | Blob>;
}

import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from '@app/core/services/auth';
import {SiteStatDto} from '@app/core/resource-dto/site-stats';

@Injectable({providedIn: 'root'})
export class SiteStatsRes extends AuthGuardedResource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);
    this.$setUrl(config.getBackendUrl('/site-stats'));
  }

  @ResourceAction()
  query: IResourceMethod<null, SiteStatDto.SiteStats>;
}

<div class="mobile-pager">

    <button class="mobile-pager__previous"
         aria-label="Järgmine leht"
         [disabled]="(isFirstPage$ | async) || (disabled$ | async)"
         (click)="previousPage()">
        <span aria-hidden="true">&lt;</span>
    </button>

    <button *ngFor="let page of (pages$ | async)"
            class="mobile-pager__number"
            [class.mobile-pager__active]="page.isActive"
            [disabled]="disabled$ | async"
            (click)="setPage(page.index)">
        {{page.page}}
    </button>

    <button class="mobile-pager__next"
         aria-label="Eelmine leht"
         [disabled]="(isLastPage$ | async) || (disabled$ | async)"
         (click)="nextPage()">
        <span aria-hidden="true">&gt;</span>
    </button>

</div>

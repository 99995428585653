import {NumberUtil} from '@app/shared/utils';

export class PaginationHelpers {

    public static getRangeLimits(center: number, max: number, buffer: number): {from: number, to: number} {
        if (!NumberUtil.isNaturalNumber(center)) throw new Error('Center must be a natural number');
        if (!NumberUtil.isNaturalNumber(max)) throw new Error('Max must be a natural number');
        if (!NumberUtil.isNaturalNumber(buffer)) throw new Error('Buffer must be a natural number');
        if (buffer < 1) throw new Error('Buffer must be strictly positive');
        if (center > max) throw new Error('Center should be smaller or equal to max');

        if (max < (2 * buffer)) return {from: 0, to: max};

        const fromCenter = center - buffer;
        const fromClamped = Math.max(fromCenter, 0);
        const toFrom = fromClamped + (2 * buffer);
        const to = Math.min(toFrom, max);
        const from = to - (2 * buffer);

        return {from, to};
    }

    public static range(from: number, to: number): number[] {
        if (!NumberUtil.isInteger(from)) throw new Error('To must be an integer');
        if (!NumberUtil.isInteger(to)) throw new Error('To must be an integer');

        const length = Math.abs(to - from) + 1;

        return Array.from({length}, (__, k) => k + from);
    }
}

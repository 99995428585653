import {Resource, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '@app/core/services/auth';

@ResourceParams({
    pathPrefix: '',
    withCredentials: true
})
export class AuthGuardedResource extends Resource {

    public constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler);
    }
}

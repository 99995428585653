import * as moment from 'moment';

export class DateUtil {

    public static readonly DATE_FORMAT = 'YYYY-MM-DD';

    public static readonly TIMESTAMP_FORMAT = 'YYYY-MM-DD\'T\'HH:mm:ss.SSS';

    public static serialize(date: Date, format?: string): string {
        if (!format) {
            format = this.DATE_FORMAT;
        }
        return date ? moment(date).format(format) : null;
    }

    public static deserialize(date: string, format?: string): Date | null {
        if (!date) return null;

        if (!format) {
            format = this.DATE_FORMAT;
        }
        const asMoment = moment(date, format);

        return asMoment.isValid() ? asMoment.toDate() : null;
    }

    public static deserializeTimestamp(date: string, format?: string): Date | null {
        if (!format) {
            format = this.TIMESTAMP_FORMAT;
        }
        return this.deserialize(date, format);
    }

    public static reverseStringDate(date: string): string {
        const parts: string[] = date.split('.');

        return parts[2] + '-' + parts[1] + '-' + parts[0];
    }
}

export class NumberUtil {

    public static isNumber(input: any): boolean {
        if (null === input) return false;
        if (isNaN(input)) return false;

        return typeof input === 'number';
    }

    public static isPositiveRealNumber(input: number | undefined): boolean {
        if (!NumberUtil.isNumber(input)) return false;
        if (!isFinite(input)) return false;

        return input >= 0;
    }

    public static isNaturalNumber(input: number | undefined): boolean {
        return this.isPositiveRealNumber(input) && Number.isInteger(input);
    }

    public static isInteger(input: number | undefined): boolean {
        if (!NumberUtil.isNumber(input)) return false;

        return Number.isInteger(input);
    }
}

import {HttpParams} from '@angular/common/http';
import {ObjectUtil} from '@app/shared/utils';

export class PagingUtil {

    public static getParams(request: any): HttpParams {
        if (!request) return new HttpParams();

        return ObjectUtil.toObject(Object.entries(request).map(([key, value]) => [key, value]));
    }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';
import {HttpStatusCode} from '@app/shared/constants';
import {ConfigService} from '@app/config/config.service';
import {Router} from '@angular/router';
import {LoggerService} from '../logger.service';
import {UserLoginRes} from '@app/core/resource/user-login.resource';
import {UserLoginDto} from '@app/core/resource-dto/user-login';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {UrlUtil} from '@app/shared/utils/url.util';
import {Representative} from '@app/core/models';
import {AuthHelpers as Helper} from '@app/core/services/auth/auth.helpers';
import User = UserLoginDto.User;
import UserCompany = UserLoginDto.UserCompany;

@Injectable({providedIn: 'root'})
export class AuthService {

    private readonly userSource = new ReplaySubject<User>(1);
    private readonly userCompanySource = new ReplaySubject<UserCompany>(1);
    private readonly representativeSource = new ReplaySubject<Representative>(1);
    private readonly sessionExpireTimeSource: ReplaySubject<Date> = new ReplaySubject();
    public readonly user$: Observable<User> = this.userSource.asObservable();
    public readonly userCompany$ = this.userCompanySource.asObservable();
    public readonly representative$ = this.representativeSource.asObservable();
    public readonly sessionExpireTime$ = this.sessionExpireTimeSource.asObservable();
    private user: User | null = null;
    private userCompany: UserCompany = null;
    public invalidCompany = false;

    public constructor(
        private config: ConfigService,
        private httpClient: HttpClient,
        private userLoginRes: UserLoginRes,
        private permissionsService: PermissionsService,
        private logger: LoggerService,
        private route: Router
    ) {
    }

    public initLogin(): Promise<User> {
        const companyId: any = UrlUtil.getParameterByName('cid');
        let userCompanyId: any = UrlUtil.getParameterByName('c');
        if (!userCompanyId) {
            userCompanyId = +localStorage.getItem('UserCompanySelection');
        }
        this.doApiLoginViaCas(companyId, userCompanyId);

        return this.user$.pipe(take(1)).toPromise();
    }

    public doApiLoginViaCas(companyId?: number, userCompanyId?: number): void {
        const casLoginUrl = this.config.get('casLoginUrl');
        const url = casLoginUrl + '?service=' + encodeURIComponent(this.config.getBackendUrl('/login/cas')) + '&gateway=true';

        this.httpClient.get(url).subscribe({
            error: _ => console.error('Cas päringu viga!'),
            complete: () => {
                userCompanyId = userCompanyId || null;
                this.userLoginRes.login({
                    companyId,
                    userCompanyId
                }).then(
                    user => this.setCurrentUser(user),
                    error => this.handleCasLoginError(error)
                );
            }
        });
    }

    public extendSession(): void {
        this.userLoginRes.refresh().then(_ => {});
    }

    public setExpirationDate(date: Date): void {
        this.sessionExpireTimeSource.next(date);
    }

    public doUserCompanyLogin(userCompany: UserCompany): void {
        this.userLoginRes.login({companyId: null, userCompanyId: userCompany.id})
            .then(
                user => this.setCurrentUser(user),
                error => this.handleCompanyLoginError(error)
            );
    }

    public setCurrentUser(user: User): void {
        this.user = user;
        if (!user) return this.userSource.error(null);

        this.userCompany = user.userCompany;
        if (this.userCompany) {
            localStorage.setItem('UserCompanySelection', this.userCompany.id + '');
            const permissions: any = Helper.mapPermissions(user);
            this.permissionsService.setPermissions(permissions);
            this.userCompanySource.next(this.userCompany);
            this.representativeSource.next(new Representative(this.user, this.userCompany, permissions));
        }
        this.userSource.next(user);

        const path = window?.location?.pathname || '/';
        if (path === '/') {
            this.route.navigate(['/orders']);
        }
    }

    public redirectToCasLogin(): void {
        const redirectUrl = this.config.get('casLoginUrl') + '?service=' + encodeURIComponent(this.getApiLoginUrl()) + '&locale=et';

        this.logger.info('AuthService::redirectToCasLogin', redirectUrl);
        window.location.href = redirectUrl;
    }

    public redirectToCasLogout(): void {
        const redirectUrl = this.config.get('casLogoutUrl') + '?service=' + encodeURIComponent(this.getApiLoginUrl()) + '&locale=et';

        this.logger.info('AuthService::redirectToCasLogin', redirectUrl);
        window.location.href = redirectUrl;
    }

    public getCurrentUser(): User {
        return this.user;
    }

    public isOccasionalApplicant(): boolean {
        return !this.user || !this.user.id;
    }

    public getCurrentUserCompany(): UserCompany | null {
        return this.userCompany ? this.userCompany : null;
    }

    public getGdprUrl(): string {
        return this.config.get('coreAppUrls').userregistry + '/gdpr?returnUrl=' + window.location.href;
    }

    private getApiLoginUrl(): string {
        return this.config.getBackendUrl('/login/cas?redirectTo=' + encodeURIComponent(window.location.href));
    }

    private handleCasLoginError(error: Response): void {
        if (error.status === HttpStatusCode.Unauthorized) {
            this.redirectToCasLogin();
        } else if (error.status === HttpStatusCode.NotFound) {
            this.userLoginRes.login().then(
                loginResult => {
                    this.invalidCompany = true;
                    this.setCurrentUser(loginResult);
                    localStorage.removeItem('UserCompanySelection');
                });
        }
    }

    private handleCompanyLoginError(error: Response) {
        if (error.status !== HttpStatusCode.Unauthorized) return;

        this.redirectToCasLogin();
    }
}

import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth';
import {NotificationDto} from '../resource-dto/notification';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
export class NotificationRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/notifications'));
    }

    @ResourceAction({
        path: '/{!notificationId}',
        method: ResourceRequestMethod.Delete,
    })
    delete: IResourceMethodStrict<{notificationId: number}, any, any, void>;

    @ResourceAction({
        method: ResourceRequestMethod.Post
    })
    save: IResourceMethodStrict<NotificationDto.InputDto, any, void, void>;
}

import {OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {ObjectUtil, StorageUtil} from '@app/shared/utils';
import {PageModel} from '@app/core/components/pagination/page.model';
import {RowAbstract} from '@app/core/components/pagination/row.abstract';
import {MetadataDto} from '@app/core/resource-dto/metadata';
import {PaginatedListWithStats} from '@app/core/components/pagination/paginated-list-with-stats.abstract';
import PagedRequest = MetadataDto.PagedRequest;
import StatsResponse = MetadataDto.StatsResponse;

export abstract class PaginatedListWithStatsAndStorage<P extends PagedRequest, R extends RowAbstract, S extends StatsResponse>
    extends PaginatedListWithStats<P, R, S> implements OnDestroy {

    private readonly storageCode: string;
    public readonly filtersForm: FormGroup;
    public readonly searchControl = new FormControl();

    protected constructor(filtersForm: FormGroup) {
        super();
        this.storageCode = this.constructor.name;
        this.filtersForm = filtersForm;

        this.init();
        this.listenToFilter();
        this.listenToPage();
    }

    private get filtersKey(): string {
        return `${this.storageCode}.filters`;
    }

    private get pageParametersKey(): string {
        return `${this.storageCode}.page-parameters`;
    }

    private get storedFilters(): any {
        const filters = StorageUtil.getItem(localStorage, this.filtersKey);
        if (!filters?.trim()?.length) return undefined;

        try {
            return JSON.parse(filters);
        } catch (e) {
            console.error(e);

            return undefined;
        }
    }

    private get storedPageParameters(): PageModel {
        const pageParameters = StorageUtil.getItem(localStorage, this.pageParametersKey);
        if (!pageParameters?.trim()?.length) return undefined;

        try {
            const json = JSON.parse(pageParameters);
            const output = PageModel.create();
            output.setOffset(json?.offset || 0);
            output.setLimit(json?.limit || 0);

            return output;
        } catch (e) {
            console.error(e);

            return undefined;
        }
    }

    private init(): void {
        this.initFilters();
        this.initPage();
    }

    private listenToFilter(): void {
        this.filter$.subscribe({next: filters => this.storeFilters(filters)});
    }

    private listenToPage(): void {
        this.page$.subscribe({next: pageParameters => this.storePageParameters(pageParameters)});
    }

    private initFilters(): void {
        const filters = this.storedFilters;
        if (filters === undefined) return;

        this.filterSubject.next(filters);
        this.restoreFilters(filters);
    }

    private initPage(): void {
        const pageParameters = this.storedPageParameters;
        if (pageParameters === undefined) return;

        this.pageSource.next(pageParameters);
    }

    private storeFilters(filters: any): void {
        const current = this.storedFilters;
        if (_.isEqual(current, filters)) return;

        ObjectUtil.filterEmptyValues(filters);
        const update = JSON.stringify(filters);
        StorageUtil.setItem(localStorage, this.filtersKey, update);
    }

    private storePageParameters(page: PageModel): void {
        const current = this.storedPageParameters;
        if (_.isEqual(current, page)) return;

        const update = JSON.stringify(page);
        StorageUtil.setItem(localStorage, this.pageParametersKey, update);
    }

    private restoreFilters(filters: any): void {
        if (!filters) return;

        this.restoreSearchFilter(filters);
        this.restoreFiltersForm(filters);
    }

    private restoreSearchFilter(filters: any): void {
        if (!!filters?.search?.trim()?.length) {
            this.searchControl.patchValue(filters.search?.trim());
        } else {
            this.searchControl.patchValue('');
        }
    }

    private restoreFiltersForm(filters: any): void {
        const allowedKeys = Object.keys(this.filtersForm.controls);
        const patch = allowedKeys.reduce((output, key) => {
            output[key] = filters[key] || null;

            return output;
        }, {} as any);

        this.filtersForm.patchValue(patch);
    }
}

import {Injectable} from '@angular/core';
import {IResourceMethod, IResourceMethodStrict, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {ContractDto} from "@app/core/resource-dto/contract";
import {AuthService} from "@app/core/services/auth";
import {ContractSettingsDto} from "@app/core/resource-dto/contract-settings";

@Injectable({providedIn: 'root'})
export class ContractRes extends AuthGuardedResource {

  constructor(
    protected requestHandler: ResourceHandler,
    protected authService: AuthService,
    protected config: ConfigService
  ) {
    super(requestHandler, authService, config);

    this.$setUrl(config.getBackendUrl('/contracts'));
  }

  @ResourceAction({expectJsonArray: false})
  query: IResourceMethod<ContractDto.QueryInput, ContractDto.QueryOutput>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/stats',
    method: ResourceRequestMethod.Get
  })
  queryStats: IResourceMethod<ContractDto.QueryStatsInput, ContractDto.QueryStatsOutput>;

  @ResourceAction({path: '/{!contractId}'})
  get: IResourceMethod<{ contractId: number }, ContractDto.Contract>;

  @ResourceAction({path: '/settings/{!userId}'})
  getSettings: IResourceMethodStrict<{ userId: number }, any, any, ContractSettingsDto.QueryOutput>;

  @ResourceAction({
    path: '/settings/{!userId}',
    method: ResourceRequestMethod.Put
  })
  updateSettings: IResourceMethodStrict<ContractSettingsDto.QueryInput, any, { userId: number }, ContractSettingsDto.QueryOutput>;

  @ResourceAction({path: '/expired'})
  getExpiredContracts: IResourceMethod<void, ContractDto.ExpiredContractModel[]>;
}
